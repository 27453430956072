.dropzone {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: #eeeeee;
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border .24s ease-in-out;
  }
  
  .dropzone:focus {
    border-color: #D93A2F;
  }
  
  .dropzone.disabled {
    opacity: 0.6;
  }
  
  .remove-icon {
    position: relative;
    display: flex;
    justify-content: right;
  }

  
  
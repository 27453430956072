.innerContianer {
    width: 98%;
    height: 98%;
    margin: 1% auto;

    @media only screen and (max-width: 640px) {
        // width: 98vw;
        // height: 98vh;
        // overflow: scroll;
        // width: 140px;
    }
}

@media screen and (min-width: 1500px) {
    #gauge-chart {
        width: 75%;
        margin: auto;
        margin-top: 10px !important;

    }

    #remove-top-margin {
        margin-top: 8% !important;
    }
}



@media screen and (min-width: 2000px) {
    #gauge-chart {
        width: 50%;
        margin: auto;
    }

    #remove-top-margin {
        margin-top: 20px !important;
    }
}

@media screen and (min-width: 3000px) {
    #gauge-chart {
        width: 30%;
        margin: auto;
    }

    #remove-top-margin {
        margin-top: 10px !important;
    }
}

@media screen and (min-width: 4000px) {
    #gauge-chart {
        width: 20%;
        margin: auto;
    }

    #remove-top-margin {
        margin-top: 10px !important;
    }
}

.innerContianerMobile {

    height: 98%;
    width: 100%;

    //margin: 1% auto;
    @media only screen and (max-width: 640px) {
        // width: 98vw;
        // height: 98vh;
        // overflow: scroll;
        // width: 140px;
    }
}


.welcomeText {
    font-weight: 500;
    text-align: left;
}

.subText {
    color: grey;
    font-size: 14px;
}

.headerText {
    text-align: left;
    font-weight: 500;
    font-size: 18px;
}

.primaryRow {
    width: 100%;
    display: flex;
    min-height: 36px;
    margin: 5px auto;
    align-items: center;
    justify-content: space-between;

    .searchBar {
        width: 20%;
        height: 24px;
        padding: 5px 10px;
        border-radius: 5px;
        border: 1px solid grey;
    }
}

.fulfillmentMobileLable {
    color: rgba(0, 0, 0, 0.6);
}

// .paddingLeftSection{
//     padding-top:"0px" !important;
//     padding-right:"4px" !important;
//   }
//   .paddingRightSection{
//     padding-top:"0px" !important;
//     padding-right:"0px" !important;
//     padding-left:"7px" !important;
//   }
//   .marginSectionTop{
//     margin-top: "26px" !important;
//   }
$small: 300px;
$medium: 900px;

.App {
  text-align: center;
}

.drawerDiv {
  display: flex;


  .drawer {
    width: 20%;
    min-width: 240px;
    max-width: 240px;
    background: #FFFFFF;
    position: fixed;
  }

  .renderComponent {
    width: 80%;
    background: #F3F3F4;
    min-width: calc(100% - 240px);
    max-width: calc(100% - 240px);

    @media only screen and (max-width: 640px) {
      width: 100vw;
      max-width: 100vw;
    }
  }

  .renderExternalComponent {
    width: 100%;
    background: #F3F3F4;
    min-width: 100%;
    max-width: 100%;

    @media only screen and (max-width: 640px) {
      width: 100vw;
      max-width: 100vw;
    }
  }
}

.UserError {
  margin-top: 50vh;

  @media only screen and (max-width: 640px) {
    margin-top: 0vh;
  }

  .title {
    font-size: 50px;
    color: #D93A2F;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .subtitle {
    font-size: 25px;
  }
}

.rtoc {
  display: flex;
  flex-direction: row;

  @media only screen and (max-width: 640px) {
    flex-direction: column;
  }
}

// Dashboard
.dCard {
  cursor: pointer;

  @media screen and (min-width: $small) and (max-width: $medium) {}

  .dCardHeader {
    align-items: center;
    display: flex;
    justify-content: center;
    color: #D93A2F;

    .header {
      text-align: center;

      p {
        font-size: 20px;

        @media screen and (min-width: $small) and (max-width: $medium) {
          //css code
          font-size: 16px;

        }
      }
    }


    .icon {
      @media screen and (min-width: $small) and (max-width: $medium) {
        //css code
        font-size: 30px;

      }

      width: 40px;
    }
  }

  .dCardLeftHeader {
    display: flex;
    color: lightgray;
    margin-left: 8px;
  }

  .number {
    color: #D93A2F;
    margin-top: 20px;

    @media screen and (min-width: $small) and (max-width: $medium) {
      //css code
      font-size: 24px;

    }
  }

  .btn {
    margin-top: 18px;
    font-size: 18px;
    min-width: 100px;
    cursor: pointer;

    @media screen and (min-width: $small) and (max-width: $medium) {
      //css code
      font-size: 16px;

    }
  }
}

.dCardCustom {
  @media screen and (min-width: $small) and (max-width: $medium) {
    //css code
    height: 145px;
    min-width: 100px;
    max-width: 142px;

  }
}

.mobileCardScroll {
  @media screen and (min-width: $small) and (max-width: $medium) {
    //css code
    flex-wrap: inherit !important;
    overflow: auto !important;

  }

}


// Order Fulfillment
.fTabs {
  @media screen and (min-width: $small) and (max-width: $medium) {
    //css code
    font-size: 12px !important;

  }

  button.MuiTab-root {
    color: #D93A2F;
    opacity: 1;
    min-height: auto;

    @media screen and (min-width: $small) and (max-width: $medium) {
      //css code
      min-height: auto;

    }
  }

  button.Mui-selected {
    color: white;
    background: #D93A2F;
  }

  button.MuiTab-root>.MuiTab-wrapper {
    display: contents !important;
  }

  .fTabIcons {
    margin-right: 14px;
  }

}

.btn {
  color: white !important;
  background: #D93A2F !important;
}

.fQuickFilters {
  background: white;
  border: 1px solid #D93A2F;

  .headingContainer {
    display: flex;
    color: #D93A2F;
    padding-bottom: 5px;
    padding-top: 5px;
    background: #eee;
  }

  .heading {
    .fTabIcons {
      vertical-align: middle;
    }

    font-size: 14px;
    text-align: center;
    color: #D93A2F;
  }

  .btnStack {
    justify-content: center;
    padding: 6px;
  }

  .topheading {
    .fTabIcons {
      vertical-align: middle;
    }

    font-size: 19px;
  }
}

.scandit-container {
  //height: calc(100% - 95px - 56px) !important
  height: 81vh !important
}

.btnStack {
  .MuiButton-label {
    font-size: 12px;
  }
}

.dataTable {
  .rdt_TableHeadRow {
    background: #D93A2F;
    color: white;
    font-size: 14px !important;

  }

  .rdt_TableCell {
    font-size: 14px !important;
  }
}

.dataTableRounded {
  .rdt_TableHeadRow {
    background: #D93A2F;
    color: white;
    font-size: 14px !important;
    border-radius: 8px;
  }

  .rdt_TableCell {
    font-size: 14px !important;
  }

  .rdt_TableRow {
    border-style: solid;
    border-width: 1px;
    border-color: rgba(0, 0, 0, .12);
    border-radius: 8px;
  }

}

.dataTableDashboardExpanded {
  padding: 10px 10px 10px 10px;

  .rdt_TableHeadRow {
    background: #eee;
    color: black;
    font-size: 14px !important;
    font-weight: 600;
  }

  .rdt_TableCell {
    font-size: 14px !important;
  }
}

.stepContaoner {
  .Mui-completed {
    color: #D93A2F !important
  }

  .MuiStepIcon-text {
    color: #D93A2F !important
  }

  .Mui-active {
    color: #D93A2F !important
  }
}

.orderDetailMobileContainer {
  .MuiFormLabel-root {
    font-size: 1.2rem !important;
  }
}

.mobileNavigationBarss {
  .MuiBottomNavigation-root {
    background: #fff;
    box-shadow: 2px 0px 6px 0px rgba(74, 71, 71, 0.75);
    -webkit-box-shadow: 2px 0px 6px 0px rgba(74, 71, 71, 0.75);
    -moz-box-shadow: 2px 0px 6px 0px rgba(74, 71, 71, 0.75);
  }

}

.paginationWeb {
  display: flex;
  justify-content: center;
  border-bottom: 1px solid white;
  border-top: 1px solid white;

  .MuiPaginationItem-page.Mui-selected {
    background: white;
    color: #D93A2F !important;
  }

  .MuiPaginationItem-page.Mui-selected:hover {
    background: white !important;
    color: #D93A2F !important;
  }

  .MuiTablePagination-root,
  .MuiSvgIcon-root {
    color: white !important;
  }
}

.paginationReceiveOrdersWeb {
  display: flex;
  justify-content: center;
  border-bottom: 1px solid white;
  border-top: 1px solid white;

  .MuiPaginationItem-page.Mui-selected {
    background: white;
    color: #D93A2F !important;
  }

  .MuiPaginationItem-page.Mui-selected:hover {
    background: white !important;
    color: #D93A2F !important;
  }

  .MuiTablePagination-root,
  .MuiSvgIcon-root {
    color: white !important;
  }
}

.fixedPagination {
  background: rgb(217, 58, 47);
  position: fixed !important;
  z-index: 99999;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: center;
}

.scrolledTopFix {
  background: rgb(217, 58, 47);
  position: fixed !important;
  z-index: 99999;
  top: 0;
  width: 100%;

  justify-content: center;
}

.scrolledTopFixFulFillOrders {
  background: #F3F3F4;
  position: fixed !important;
  z-index: 99999;
  top: 0;
  width: 100%;

  justify-content: center;
}

.searchWeb {
  width: 321px;
  margin-bottom: 3px;
  margin-top: 10px;
  margin-left: 5%;
  padding: 0px !important;
}

.appBarPrimary {
  background: #D93A2F !important;
  color: white;
}

.scanColor {
  color: #D93A2F !important;
  margin-left: 4px !important;
}

.scanColorWhite {
  color: #ffffff !important;
  margin-left: 4px !important;
}

.speedDialPickOrder {
  bottom: 0 !important;

  .MuiButtonBase-root {
    background: #D93A2F !important;
    color: white;
  }
}

.customerDetailsBoxPickOrder {
  position: fixed;
  width: 100%;
  bottom: 53px;
}

.paperToBottomPadding {
  padding-top: 6px;
  padding-bottom: 6px;
}

.textLeft {
  text-align: left;
}

.textRight {
  text-align: right;
}

.textCenter {
  text-align: center;
}

.backdrop {
  width: 100%;

  background: #D93A2F;

}

.title {
  padding: 5px 15px;
  font-size: 25px;
  color: #ffffff;
  font-weight: 500px;
  display: flex;
  align-items: center;
}

.white {
  background: white !important;
}

.mainContainer {
  background: #ffffff !important;

  // border: 1px solid #eee;
  @media screen and (min-width: $medium) {
    //css code
    border: 1px solid #c3c3c3;

  }

}

.mainContainerPackOrderGenerateLabel {
  background: #ffffff !important;
  // border: 1px solid #eee;
  border-left: 1px solid #c3c3c3;
  border-right: 1px solid #c3c3c3;
  border-bottom: 1px solid #c3c3c3;
}

.mainContainerPackOrderHelp {
  border: 1px solid #c3c3c3;
}

html {
  overflow: scroll;
  overflow-x: hidden;
}

.rightAlign {
  display: flex;
  justify-content: flex-end;
}

.btnMblCancel {
  width: 33% !important;
  padding: 3px !important;
  font-size: 10px !important;
  margin-right: 4px !important;
}

.btnMblResource {
  width: 31% !important;
  padding: 3px !important;
  font-size: 10px !important;

}

.containerTypeBarCodeMbl {
  position: fixed;
  width: 100%;
  z-index: 99999;
  top: 82%;
}

.customChip {
  border-radius: 23px !important;
  padding: 11px !important;
  font-size: 18px !important;
  height: 52px !important;
}

.customAccordian {
  .MuiAccordionSummary-root {
    background: #D93A2F !important;
    color: white !important;
    min-height: 47px !important;

    .MuiAccordionSummary-content p {
      font-size: 16px !important;
    }

    .MuiAccordionSummary-expandIconWrapper svg {
      color: white !important;
    }
  }

  .tblAccordian {
    p {
      font-size: 12px;
    }

    .tblAccordianTd {
      display: flex !important;
      font-weight: 500 !important;

      svg {

        margin-right: 7px !important;
      }
    }

    .txtLeftBold {
      text-align: left;

      p {
        color: #3f3f3f;
        font-size: 15px;
      }

    }

  }
}

.textAlignLeft {
  text-align: left;
}

.txtQP {
  .MuiOutlinedInput-input {
    height: 6px !important;
  }
}

.primaryColor {
  color: #D93A2F !important;
}

.packTopTileIcon {
  background: #D93A2F;
  border-radius: 50px;
  color: white;
  height: 26px !important;
  width: 26px !important;
  padding: 4px;
}

.packTopTilekey {
  margin-left: 6px !important;
  margin-top: 5px !important;
  font-size: 1rem !important;
}

.packTopTileValue {
  margin-top: 5px !important;
  margin-left: 7% !important;
  font-weight: 800 !important;
  font-size: 1rem !important;

}

.drpConatinerBoxType {

  text-align: center !important;

}

.inputsNormalGrid {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.verticalCenter {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
}

.packOrderUnPackagedHeader {
  padding: 5px !important;
  background: #D93A2F !important;
  //margin-top: 5px !important;
  color: white !important;
  border-radius: 0 !important;
  box-shadow: none !important;

  h6 {
    color: white !important;
    font-weight: 400 !important;
    font-size: 1rem !important;
  }
}

.packOrderPackageHeader {
  padding: 5px !important;
  background: #D93A2F !important;
  //margin-top: 5px !important;
  color: white !important;
  border-radius: 0 !important;


  h6 {
    color: white !important;
    font-weight: 400 !important;
    font-size: 1rem !important;
  }
}

.packOrderPackageHeaderPackChild {
  padding: 5px !important;
  background: #828181 !important;
  margin-top: 5px !important;
  color: white !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;

  h6 {
    color: white !important;
    font-weight: 400 !important;
    font-size: 1rem !important;
  }
}

.boxCharacterHeader {
  display: flex;
  justify-content: space-between;
  background: #828181 !important;
  color: white !important;
  padding: 5px;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.packOrderPackageHeaderChild {
  padding: 5px !important;
  background: #f2554a !important;
  color: white !important;
  border-radius: 0 !important;


  .icon {
    font-size: 3rem;
    border-radius: 50px;
    margin-right: 20px;
  }

  h6 {
    color: white !important;
    font-weight: 600 !important;
    font-size: 1rem !important;
  }
}

.orderBoxDiv {
  width: 98%;
  margin-left: 10px;
  box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, 0.25), 0px 1px 1px 1px rgba(0, 0, 0, 0.16), 0px 1px 3px 1px rgba(0, 0, 0, 0.14);
  border-radius: 4px;
  margin-bottom: 15px;
}

.orderCharaterBoxDiv {
  box-shadow: 0px 1px 1px -1px rgba(0, 0, 0, 0.25), 0px 1px 1px 0px rgba(0, 0, 0, 0.16), 0px 1px 3px 0px rgba(0, 0, 0, 0.14);
  border-radius: 4px;
  margin-bottom: 5px;
}

.packOrderPackageItem {
  padding: 5px !important;

  border-radius: 0 !important;
  padding-top: 20px !important;
  padding-bottom: 20px !important;

  .headerRow {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 5px;
    padding-right: 5px;
  }
}

.packOrderItemImage {
  //border: 1px solid #eee;
  padding: 10px;
  height: 100px !important;
}

.inputField {
  text-align: left !important;

  p {
    text-align: left !important;
    margin-bottom: 3px !important;
    margin-top: 3px !important;
  }

  .MuiSelect-select {
    text-align: left !important;
  }

  .MuiFormControl-root {
    min-width: 100% !important;
    margin-top: 3px !important;
    margin-bottom: 3px !important;
  }

  .MuiFormControlLabel-root {
    margin-left: 0px !important;
  }
}

.chipTracking {
  svg {
    color: white !important;
  }
}

.customRadio {
  .MuiFormControlLabel-root {
    background: #eee;
    width: 100%;
    margin-top: 6px;
    margin-bottom: 6px;
    padding-bottom: 10px;
    padding-top: 10px;
  }

  .PrivateSwitchBase-input {
    color: #f2554a !important;
  }

  .MuiFormControlLabel-root:hover {
    background: white;
    border: 1px solid #eee !important;
  }
}

.externalcustomRadio {
  .MuiFormControlLabel-root {
    background: #eee;
    width: 100%;
    margin-top: 6px;

  }

  .PrivateSwitchBase-input {
    color: #f2554a !important;
  }

  .MuiFormControlLabel-root:hover {
    background: white;
    border: 1px solid #eee !important;
  }
}

.externalcustomRadio2 {


  .MuiFormGroup-root {
    display: block;

  }

  .MuiFormControlLabel-root {
    background: #eee;
    width: 33%;
    display: inline-block;
    padding: 0;
    margin-right: 0;

  }

  .PrivateSwitchBase-input {
    color: #f2554a !important;
  }

  .MuiFormControlLabel-root:hover {
    background: white;
    border: 1px solid #eee !important;
  }
}



.MuiAccordionSummary-root {
  min-height: 35px !important;
}

.MobileDrawerButtonsExternalLink {
  color: #ffffff !important;
  border: 1px solid #227575 !important;
  width: 48% !important;
  background-color: #227575 !important;
  margin-left: 2px !important;
  margin-right: 2px !important;
  white-space: nowrap !important;
}

.MobileDrawerButtonsExternalLinkDisabled {
  color: #ffffff !important;
  border: 1px solid rgba(0, 0, 0, 0.26) !important;
  width: 48% !important;
  background-color: rgba(0, 0, 0, 0.26) !important;
  margin-left: 2px !important;
  margin-right: 2px !important;
  white-space: nowrap !important;
}

.MobileDrawerButtons {
  color: #ffffff !important;
  border: 1px solid #D93A2F !important;
  width: 48% !important;
  background-color: #D93A2F !important;
  margin-left: 2px !important;
  margin-right: 2px !important;
  white-space: nowrap !important;
}

.MobileDrawerButton {
  color: #ffffff !important;
  border: 1px solid #D93A2F !important;
  width: 98% !important;
  background-color: #D93A2F !important;
  margin-left: 2px !important;
  margin-right: 2px !important;
  white-space: nowrap !important;
}

.SearchHover:hover {
  background-color: #eee;
}

.ActionLoader {
  background-size: 20vh 20vh;
  background-color: rgba($color: #0A0D0F, $alpha: .5);
  //background-color: #0A0D0F ;
  bottom: 0;
  height: 100%;
  left: 0;
  //opacity: .2000;
  // -moz-opacity: .55;
  position: fixed;
  width: 100%;
  z-index: 999999
}

.fQuickFiltersFulfillOrders {
  background: white;
  //margin-top: 3px;
  border: 1px solid #D93A2F;
  border-radius: 2px !important;

  .MuiAccordionSummary-content {
    margin: 0px !important;
  }

  .headingContainer {
    display: flex;
    color: #D93A2F;
    padding-bottom: 0px;
    padding-top: 0px;
    background: #eee;
  }

  .heading {
    .fTabIcons {
      vertical-align: middle;
    }

    font-size: 14px;
    text-align: center;
    color: #D93A2F;
  }

  .btnStack {
    justify-content: center;
    padding: 6px;
  }

  .topheading {
    .fTabIcons {
      vertical-align: middle;
    }

    font-size: 19px;
  }
}

.backGroundWhite {
  background: white;
}

.disabledColor {
  color: rgba(0, 0, 0, 0.26) !important;
}

.disabledButton {
  background-color: rgba(0, 0, 0, 0.26) !important;
}

.enabledColor {
  Color: #D93A2F !important
}

.overlay {
  background-color: rgba(0, 0, 0, 0.3);
  cursor: no-drop;
}

.disableClass {
  opacity: 0.7;
}

.flexClass {
  display: flex;
  justify-content: space-between;
}

.AuditFiltersContainer {
  padding: 5px;
  margin: 5px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media only screen and (max-width: 640px) {
    flex-direction: column;
    align-items: center;
  }

  .chipFilters {
    display: flex;
    width: 100%;

    @media only screen and (max-width: 640px) {
      flex-direction: column;
      align-items: center;
    }

    .logType {
      width: 820px;
    }

    .appType {
      width: 160px;
    }
  }

  .pagination {
    height: 80px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;

    .buttons {
      display: flex;
      margin-left: 10px;
      align-items: center;

      .text {
        width: auto;
        margin: 0 5px;
        min-width: 100px;
      }
    }

    .dropdown {
      width: 60px;
      padding: 2px;
      margin-left: 5px;
      background: #eaeaea;
    }
  }
}

.techAuditDetailTableRowHeight {
  height: 50px;
}

.techAuditDetailfirstTd {
  width: "30%";
}

.techAuditDetailsecondTd {
  width: "70%";
  text-align: left;
}

.resourceCount {
  color: white;
  font-size: 11px;
  padding: 4px;
  border-radius: 50px;
  position: absolute;
  margin-top: -12px;
  background: #D93A2F;
  width: 17px;
  height: 17px;
  text-align: center;
}

.picklistTableCollaped {
  border-bottom: 1px solid #000;
  border-left: 1px solid #000;
  padding-left: 3px;
}

.packingListTable {
  border-spacing: 0;

}

@media only screen and (max-width: 1360px) and (min-width: 400px) {
  .selectStore {
    margin-left: 30px;
  }
}

.image-zoom {
  position: relative;
  cursor: zoom-in;
}

.image-zoom::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.05);
  display: none;
}

.image-zoom:hover::before {
  display: block;
}

.image {
  margin-right: 10px;
  padding: 2px;
  width: 130px;
  height: 130px;
  object-fit: cover;

  @media only screen and (max-width: 640px) {
    width: 80px;
    height: 80px;
  }

  background-size: cover;
  border: 1px solid lightgrey;
}

.remove-buttons input[type=number]::-webkit-inner-spin-button,
.remove-buttons input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.selectStore .MuiAutocomplete-inputRoot {
  flex-wrap: nowrap !important;
}

.scroll-class {
  overflow-x: auto
}

.scroll-class::-webkit-scrollbar {
  width: 0;
}

.scroll-class::-webkit-scrollbar-thumb {
  background-color: #9e9e9e;
  border-radius: 0;
}

.vl {
  border-left: 2px solid lightgrey;
  height: 50px;
  margin-top: 18px;
}

.tiles-icons {
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.tiles-header-font {
  color: #7d8c81;
  margin-bottom: 0;
  margin-top: 20px;
  margin-left: 5px;
  font-size: 12px;
  font-weight: bold;
  word-break: break-word;
}

.tiles-header-font-span {
  color: #7d8c81;
  margin-bottom: 0;
  margin-left: 5px;
  font-size: 14px;
  font-weight: bold;
  word-break: break-word;
}

.tiles-value-font {
  margin-top: 5px;
  margin-left: 10px;
  word-break: break-word;
  font-weight: bold;
  font-size: 24px;
}

.grey-value-font {
  color: "white";
  font-size: "12px"
}
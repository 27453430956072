@media screen and (max-width: 1000px) and (orientation: landscape) {
  .landscape {
      position: absolute;
      top: 0;
      right: 0px;
      bottom: 0px;
      left: 0px;
 }
//   .scandit-container {
//       height: 200vh !important;
//       display: block;
//       transform-origin: top left;
//       transform: rotate(-90deg) translate(-100%);
//       margin-top: 0%;
//       margin-left: 30px;
//       width: 75vh !important;
//  }
.MuiBottomNavigation-root{
    display: none !important;
 }
 .mobileNabvarContainer{
    display: none !important;
 }
//   .hideWhileRotate {
//       display: none !important;
//  }
  .containerTypeBarCodeMbl{
      display: none !important;
 }
}

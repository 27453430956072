.grid-icons
{
  color:white;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.grid-header-font
{
  color: #7d8c81;
  margin-bottom:0;
  margin-top:20px;
  margin-left:10px;
  font-size: 14px;
  font-weight: bold;
  word-break: break-word;
}

.grid-value-font
{
  margin-top: 5px;
  margin-left: 10px;
  font-size: 1.8rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.235;
  letter-spacing: 0.00735em;
}

.innerContianer {
  width: 98%;
  height: 98%;
  margin: 1% auto;
}

.graph-scroll {
  @media only screen and (max-width: 1500px) {
      overflow-x: auto;
  }
}

.subscript-value{
    margin-left: 5px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: bold;
}